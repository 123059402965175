import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import * as S from "./styles"

const LandingPage = () => {
  const { logo } = useStaticQuery(
    graphql`
      query {
        logo: file(relativePath: { eq: "go-burguer-logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  return (
    <S.ComponentWrapper>
      <S.Container>
        <S.Logo fluid={logo.childImageSharp.fluid} />
        <S.LocationWrapper>
          <div>
            <S.Title>Onde você está?</S.Title>
            <S.SutTitle>Selecione a unidade mais próxima de você:</S.SutTitle>
          </div>
          <S.ButtonWrapper>
            <S.Button to="/anapolis">Anápolis</S.Button>
            <S.Button to="/brasilia">Brasília</S.Button>
          </S.ButtonWrapper>
        </S.LocationWrapper>
      </S.Container>
    </S.ComponentWrapper>
  )
}

export default LandingPage
