import styled from "styled-components"
import Img from "gatsby-image"
import { Link } from "gatsby"

import bgLp from "../../images/landing-page-bg.jpg"

export const ComponentWrapper = styled.div`
  background: url(${bgLp}) center/cover;
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
`

export const Container = styled.div`
  display: flex;
  justify-content: space-around;
  height: 80%;
  align-items: center;
  flex-direction: column;
`

export const Logo = styled(Img)`
  width: 100%;
  max-width: 300px;
`

export const LocationWrapper = styled.div`
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Title = styled.h2`
  font-family: Arvo;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  font-size: 32px;
  color: white;
`

export const SutTitle = styled.h4`
  text-transform: uppercase;
  color: white;
  font-weight: 400;
  letter-spacing: 1.4px;
  margin-top: 2px;
  font-size: 14px;
  text-align: center;
`
export const Button = styled(Link)`
  font-family: Arvo;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  margin: 10px;
  border: solid 2px;
  padding: 10px 50px;
  font-weight: bold;
  font-size: 18px;
  transition: 0.3s all;
  max-width: 202px;

  &:hover {
    background: black;
    border-color: black;
  }
`

export const ButtonWrapper = styled.div`
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }
`
